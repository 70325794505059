import { ACTION } from 'src/constants/textAction';
import ScheduleMonth from './ScheduleMonth';
import {
  Button,
  Input,
  Form,
  notification,
  PlusOutlined,
  Tabs,
  TabsProps
} from 'core-ui';
import useCustomDrawer from 'src/hooks/useCustomDrawer';
import ScheduleCU from './ScheduleCU';
import { useEffect, useState } from 'react';
import { TypeAction } from '../Payment';
import { useSelector } from 'react-redux';
import ScheduleDay from './ScheduleDay';
import ScheduleWeek from './ScheduleWeek';
import { useQuery, useQueryClient } from 'react-query';
import { getScheduleList } from 'src/services/schedule';
import { notifyMessage } from 'src/constants/message';
import { getErrorMsgFromResponse } from 'src/constants/format';
import { ISchedule, ScheduleEventI } from 'src/models/schedule';
import {
  dateHelper,
  dayjs,
  FormatTemplate,
  Locale,
  stringHelper,
  TimeZone
} from 'core-helpers';
import {
  FORMAT,
  parsedDateMonth,
  parsedDateDayWeek,
  getStartEndDateOfMonth
} from 'src/constants/datetime';
import { IUser } from 'src/models/user';
import LoadingSpin from 'src/components/LoadingSpin';
import { getUserList } from 'src/services/user';
import { ISelect } from 'src/models/select';
import { RootState } from './ScheduleDay/CustomTimeLine';
import scheduleSlice from 'src/redux/scheduleSlice';
import { AuthState } from 'src/redux/authInfoSlice';
import CoreSelect from 'src/components/CoreSelect';
import styles from './style.module.scss';
import clsx from 'clsx';
import { getVehicleList } from 'src/services/vehicle';
import { getGroupList } from 'src/services/group';
import { useAppDispatch } from 'src/redux/hooks';
import { da, id } from 'date-fns/locale';

interface ISearchSchedule {
  vehicle_id_eq?: number;
  user_id_eq?: number;
  schedule_users_user_id_eq?: number;
  schedule_groups_group_id_eq?: number;
  key_word?: string | undefined;
}

export interface Employee {
  id: number;
  title: string;
}

export enum activeViews {
  Month = 'month',
  Week = 'week',
  Day = 'day'
}

export enum typeDate {
  Start = 'start',
  End = 'end'
}

export enum typeAction {
  Create = 'start',
  Detail = 'detail',
  Edit = 'edit'
}

export enum tabSchedule {
  Responsibility = 'responsibility',
  Participation = 'participation',
  Group = 'group',
  Vehicle = 'vehicle',
  All = 'all'
}

export type TypeTabScheduleAction =
  | 'responsibility'
  | 'participation'
  | 'group'
  | 'vehicle'
  | 'all';

const Schedule = () => {
  const [formDrawer] = Form.useForm();

  const [formSearch] = Form.useForm();

  const queryClient = useQueryClient();

  const dispatch = useAppDispatch();

  const [valueRadioDate, setValueRadioDate] = useState<boolean>(true);

  const [valueRadioSelect, setValueRadioSelect] = useState<boolean>(true);

  const [valueRadioColor, setValueRadioColor] = useState<Number>(1);

  const [appointmentRequestCheck, setAppointmentRequestCheck] =
    useState<boolean>(true);

  const { setActionView, setStartTime, setEndTime } = scheduleSlice.actions;

  const { startTime, endTime, statusActionTabView } = useSelector(
    (state: RootState) => state.schedule
  );

  const defaultParams = {
    start_date: startTime,
    end_date: endTime,
    schedule_group_user: undefined,
    responsible_user_id: undefined,
    q: {
      user_id_eq: undefined,
      id_eq: undefined,
      schedule_users_user_id_eq: undefined
    }
  };
  const [typeAction, setTypeAction] = useState<TypeAction>('create');

  const [listScheduleData, setListScheduleData] = useState<ScheduleEventI[]>(
    []
  );

  const auth: IUser | any = useSelector(
    (state: AuthState) => state?.auth?.user
  );

  const [params, setParams] = useState<ISchedule>(defaultParams);

  const [typeTabSchedule, setTypeTabSchedule] = useState<TypeTabScheduleAction>(
    tabSchedule.Responsibility
  );

  const isTabGroup = typeTabSchedule?.includes(tabSchedule.Group);

  const isTabAll = typeTabSchedule?.includes(tabSchedule.All);

  const isTabResponsibility = typeTabSchedule?.includes(
    tabSchedule.Responsibility
  );

  const isTabParticipation = typeTabSchedule?.includes(
    tabSchedule.Participation
  );

  const [listEmployeeCharge, setListEmployeeCharge] = useState<Employee[]>([
    { id: 0, title: '' }
  ]);

  const isCreateForm = typeAction?.includes('create');

  const actionView = useSelector(
    (state: { schedule: { actionView: string } }) => state?.schedule?.actionView
  );

  const [isStatusValidateDatePicker, setIsStatusValidateDatePicker] =
    useState<boolean>(false);

  const {
    isOpen: isOpenForm,
    openDrawer: openForm,
    closeDrawer: closeForm
  } = useCustomDrawer();

  const showDrawEdit = () => {
    setTypeAction('edit');
    openForm();
  };

  const resetAllFields = () => {
    formDrawer?.resetFields();
    setIsStatusValidateDatePicker(false);
    setValueRadioDate(true);
    setValueRadioSelect(true);
    setValueRadioColor(1);
    setAppointmentRequestCheck(true);
  };

  const closeDialog = () => {
    closeForm();
    resetAllFields();
  };

  const onSearchSchedule = (values: ISearchSchedule) => {
    setParams((prev: ISchedule) => {
      return {
        ...prev,
        q: {
          vehicle_id_eq: values.vehicle_id_eq,
          user_id_eq: values.user_id_eq,
          schedule_users_user_id_eq: values.schedule_users_user_id_eq,
          schedule_groups_group_id_eq: values.schedule_groups_group_id_eq
        },
        key_word: values?.key_word ? values?.key_word.trim() : ''
      };
    });
  };

  const items: TabsProps['items'] = [
    {
      key: 'responsibility',
      label: '担当'
    },
    {
      key: 'participation',
      label: '参加'
    },
    {
      key: 'group',
      label: 'グループ'
    },
    {
      key: 'vehicle',
      label: '車両'
    },
    {
      key: 'all',
      label: '全て'
    }
  ];

  const { data: dataListVehicle } = useQuery(
    ['getDataListVehicle'],
    () =>
      getVehicleList({
        all: true
      }),
    {
      refetchOnWindowFocus: false,
      retry: false,
      onError: (e: any) => {
        e?.response?.status === 500
          ? notification.error({
              message: notifyMessage?.sever_error
            })
          : notification.error({
              message: getErrorMsgFromResponse(e)
            });
      }
    }
  );

  const { data: dataListAllUsers } = useQuery(
    ['dataListAllUsers'],
    () =>
      getUserList({
        all: true
      }),
    {
      refetchOnWindowFocus: false,
      retry: false,
      onError: (e: any) => {
        e?.response?.status === 500
          ? notification.error({
              message: notifyMessage?.sever_error
            })
          : notification.error({
              message: getErrorMsgFromResponse(e)
            });
      }
    }
  );

  const newListUser = dataListAllUsers?.data?.users?.map(
    (element?: ISelect) => {
      return {
        label: element?.name,
        value: element?.id
      };
    }
  );

  const newListVehicle = dataListVehicle?.data?.vehicles?.map(
    (element: { id?: number; name?: string }) => {
      return {
        label: element?.name,
        value: element?.id
      };
    }
  );

  const { data: dataListAllGroups } = useQuery(
    ['getDataListAllGroups'],
    () =>
      getGroupList({
        q: {
          all: true
        }
      }),
    {
      refetchOnWindowFocus: false,
      retry: false,
      onError: (e: any) => {
        e?.response?.status === 500
          ? notification.error({
              message: notifyMessage?.sever_error
            })
          : notification.error({
              message: getErrorMsgFromResponse(e)
            });
      }
    }
  );

  const newListGroup = dataListAllGroups?.data?.groups?.map(
    (element?: { name?: string; id?: number }) => {
      return {
        label: element?.name,
        value: element?.id
      };
    }
  );

  const formatEventDate = (
    date: Date | string | undefined,
    isAllDay?: boolean | undefined
  ): string => {
    return dateHelper.formatDate({
      date,
      format: isAllDay
        ? FormatTemplate['YYYY/MM/DD']
        : FormatTemplate['YYYY/MM/DD HH:mm:ss'],
      locale: Locale.JA,
      timeZone: TimeZone['Asia/Tokyo']
    });
  };

  const checkDates = (startDate: string, endDate: string) => {
    const start = dayjs(startDate);
    const end = dayjs(endDate);

    const isDifferentDay = !start.isSame(end, 'day');

    const isEndOfDay = end.format('HH:mm:ss') === '00:00:00';

    return isDifferentDay && isEndOfDay;
  };

  const checkMonth = (start: string, end: string): boolean => {
    const startDate = dayjs(start);
    const endDate = dayjs(end);

    const startMonth = startDate.month();
    const startYear = startDate.year();
    const endMonth = endDate.month();
    const endYear = endDate.year();

    return startMonth !== endMonth || startYear !== endYear;
  };

  const getFormatTitle = (
    is_all_day: boolean,
    startOfMonth: string,
    endOfMonth: string
  ) => {
    if (!is_all_day && startOfMonth && endOfMonth) {
      return checkMonth(startOfMonth, endOfMonth)
        ? FORMAT.FORMAT_MONTH_DAY_HOUR
        : FORMAT.FORMAT_DAY_HOUR;
    }

    if (
      is_all_day &&
      startOfMonth &&
      endOfMonth &&
      checkMonth(startOfMonth, endOfMonth)
    ) {
      return FORMAT.FORMAT_MONTH_DAY;
    }

    return FORMAT.FORMAT_DAY;
  };

  // get employee responsibility participation

  const getListEmployeeResponsibilityParticipation = (
    data?: ScheduleEventI[]
  ): { id: number; title: string }[] | undefined => {
    const listUserScheduleGroups = data?.flatMap((schedule) =>
      schedule?.schedule_groups?.flatMap((group: { group_id?: number }) => {
        const listGroups = dataListAllGroups?.data?.groups;
        const foundGroup = listGroups.find(
          (newGroup: { id?: number }) => newGroup.id === group?.group_id
        );
        return foundGroup
          ? foundGroup.group_users.map(
              (data: { user_id?: number; user: { name: string } }) => ({
                id: data?.user_id,
                title: data?.user?.name
              })
            )
          : [];
      })
    );

    const listUserScheduleUsers = data
      ?.flatMap(
        (item) =>
          item.schedule_users?.map(
            (values: { user_id?: number; user?: { name?: string } }) => ({
              id: values?.user_id,
              title: values?.user?.name
            })
          ) ?? []
      )
      ?.reduce(
        (acc, current) => {
          if (
            current &&
            current.id !== undefined &&
            current.title !== undefined
          ) {
            const ids = new Set(acc.map((item) => item?.id));
            if (!ids.has(current.id)) {
              acc.push({ id: current.id, title: current.title });
            }
          }
          return acc;
        },
        [] as { id: number; title: string }[]
      );

    return listUserScheduleUsers?.concat(listUserScheduleGroups ?? []);
  };

  // get employee all

  const getListEmployeeAll = (
    data?: ScheduleEventI[]
  ): { id: number; title: string }[] | undefined => {
    if (!data || data.length === 0) {
      return [];
    }

    const dataEmployeeUsersGroups = !actionView.includes(activeViews.Month)
      ? getListEmployeeResponsibilityParticipation(data)
      : [];

    const dataEmployeeInChargeResult = newListUser
      .filter((user: { value?: number }) =>
        data.some(
          (element: { user_id?: number }) => element.user_id === user.value
        )
      )
      .map((element: { value?: number; label?: string }) => ({
        id: element.value!,
        title: element.label!
      }));

    const uniqueEmployees = new Map<number, string>();

    [
      ...(dataEmployeeInChargeResult || []),
      ...(dataEmployeeUsersGroups || [])
    ].forEach((employee: { id: number; title: string }) => {
      if (!uniqueEmployees.has(employee.id)) {
        uniqueEmployees.set(employee.id, employee.title);
      }
    });

    return Array.from(uniqueEmployees, ([id, title]) => ({ id, title }));
  };

  // get employee group
  const getListEmployeeGroup = (data: ScheduleEventI[]) => {
    const groupIds = data?.flatMap(
      (item) =>
        item.schedule_groups?.map(
          (group: { group_id?: number }) => group?.group_id
        ) ?? []
    );
    return groupIds?.map((id) => {
      return {
        id,
        title:
          newListGroup?.find((elementUser: ISelect) => elementUser.value === id)
            ?.label ?? ''
      };
    });
  };

  // get employee vehicle
  const getListEmployeeVehicle = (data: ScheduleEventI[]) => {
    return data?.map((element: ScheduleEventI) => {
      const idResult = typeTabSchedule?.includes(tabSchedule.Vehicle)
        ? element?.vehicle_id
        : element?.user_id;
      const newListResult = typeTabSchedule?.includes(tabSchedule.Vehicle)
        ? newListVehicle
        : newListUser;
      return {
        id: idResult,
        title:
          newListResult?.find(
            (elementUser: ISelect) => elementUser.value === idResult
          )?.label ?? ''
      };
    });
  };

  // get schedules
  const getSchedulesTabResponsibilityParticipation = (
    dataSchedulesCustom: ScheduleEventI[]
  ) => {
    const dataSchedulesUsers =
      Array.isArray(dataSchedulesCustom) && dataSchedulesCustom.length > 0
        ? dataSchedulesCustom?.flatMap(
            (element: ScheduleEventI) =>
              element.schedule_users?.map((user: { user_id: number }) => ({
                ...element,
                id: stringHelper.uuidv4(),
                group: user?.user_id
              })) ?? []
          )
        : [];

    const dataSchedulesGroups =
      Array.isArray(dataSchedulesCustom) && dataSchedulesCustom.length > 0
        ? dataSchedulesCustom?.flatMap(
            (element: ScheduleEventI) =>
              element.schedule_groups_sub?.map((group: { id: number }) => ({
                ...element,
                id: stringHelper.uuidv4(),
                group: group.id
              })) ?? []
          )
        : [];

    return dataSchedulesUsers?.concat(dataSchedulesGroups ?? []);
  };

  const getSchedulesTabAll = (dataSchedulesCustom: ScheduleEventI[]) => {
    const dataSchedulesUsersGroups =
      getSchedulesTabResponsibilityParticipation(dataSchedulesCustom);

    const dataSchedulesInCharge: any = dataSchedulesCustom
      ?.map((item) => {
        return {
          ...item,
          group: item?.user_id
        };
      })
      ?.filter(
        (item1Entry) =>
          !dataSchedulesUsersGroups.some(
            (item2Entry) =>
              item2Entry.id_schedule === item1Entry.id &&
              item2Entry.group === item1Entry.group
          )
      );

    const dataSchedulesTabAll = dataSchedulesUsersGroups?.concat(
      dataSchedulesInCharge ?? []
    );

    return dataSchedulesTabAll;
  };
  const groupsUsersCustom = dataListAllGroups?.data?.groups?.flatMap(
    (group: { group_users: { group_id?: number; user_id?: number }[] }) =>
      group.group_users.map(
        (user: { group_id?: number; user_id?: number }) => ({
          group_id: user.group_id,
          user_id: user.user_id
        })
      )
  );

  const { isLoading, isFetching } = useQuery(
    [
      'getDataListSchedule',
      startTime,
      endTime,
      actionView,
      params,
      typeTabSchedule,
      statusActionTabView
    ],
    () => getScheduleList(params),
    {
      refetchOnWindowFocus: false,
      retry: false,
      onSuccess: (values) => {
        const schedules = values?.data?.schedules;
        const dataSchedulesCustom =
          schedules?.length > 0
            ? schedules?.map((element: ScheduleEventI) => {
                const startDate = formatEventDate(
                  element?.start_date,
                  element?.is_all_day
                );
                const endDate = formatEventDate(
                  element?.end_date,
                  element?.is_all_day
                );
                const isSameDay =
                  element?.start_date && element?.end_date
                    ? dayjs(element.start_date).isSame(dayjs(element.end_date))
                    : false;

                const startOfMonth = element?.start_date
                  ? parsedDateMonth(element.start_date, typeDate.Start)
                  : '';
                const endOfMonth = element?.end_date
                  ? parsedDateMonth(
                      element?.end_date,
                      typeDate.End,
                      isSameDay,
                      element?.is_all_day
                    )
                  : '';

                const isMonthView =
                  actionView.includes(activeViews.Month) &&
                  element?.end_date &&
                  startOfMonth &&
                  endOfMonth;

                const addThirtyMinutes = (dateTime: string) => {
                  return dayjs(dateTime)
                    .add(30, 'minute')
                    .format('YYYY/MM/DD HH:mm:ss');
                };

                const startResult =
                  actionView.includes(activeViews.Month) && element?.start_date
                    ? startOfMonth
                    : element?.start_date &&
                        actionView.includes(activeViews.Week)
                      ? parsedDateDayWeek(
                          element?.start_date,
                          typeDate.Start,
                          activeViews.Week
                        )
                      : element?.start_date &&
                          actionView.includes(activeViews.Day)
                        ? parsedDateDayWeek(
                            element?.start_date,
                            typeDate.Start,
                            activeViews.Day
                          )
                        : '';
                const endResult =
                  isMonthView && checkDates(startOfMonth, endOfMonth)
                    ? addThirtyMinutes(endOfMonth)
                    : isMonthView && !checkDates(startOfMonth, endOfMonth)
                      ? endOfMonth
                      : element?.end_date &&
                          actionView.includes(activeViews.Week)
                        ? parsedDateDayWeek(
                            element?.end_date,
                            typeDate.End,
                            activeViews.Week
                          )
                        : element?.end_date &&
                            actionView.includes(activeViews.Day)
                          ? parsedDateDayWeek(
                              element?.end_date,
                              typeDate.End,
                              activeViews.Day
                            )
                          : '';

                const formatTitle = getFormatTitle(
                  element?.is_all_day ?? false,
                  startOfMonth ?? '',
                  endOfMonth ?? ''
                );

                const listEmployeeCharge = [{ user_id: 1 }, { user_id: 2 }];

                const listUser = [
                  { id: 1, name: 'user-1' },
                  { id: 2, name: 'user-2' },
                  { id: 3, name: 'user-3' }
                ];

                const result = [
                  { id: 1, name: 'user-1' },
                  { id: 2, name: 'user-2' }
                ];

                const listUserScheduleGroups =
                  element?.schedule_groups?.flatMap(
                    (group: { group_id?: number }) => {
                      const listGroups = dataListAllGroups?.data?.groups;
                      const foundGroup = listGroups?.find(
                        (newGroup: { id?: number }) =>
                          newGroup.id === group?.group_id
                      );
                      return foundGroup
                        ? foundGroup?.group_users.map(
                            (data: {
                              user_id?: number;
                              user: { name: string };
                            }) => ({
                              id: data?.user_id,
                              title: data?.user?.name
                            })
                          )
                        : [];
                    }
                  );

                // tab all
                const groupIdsCustom =
                  element?.schedule_groups &&
                  element?.schedule_groups.map(
                    (group: { group_id?: number }) => group.group_id
                  );

                const listUserScheduleGroupResult = groupsUsersCustom.filter(
                  (user: { group_id?: number }) =>
                    (groupIdsCustom ?? []).includes(user.group_id)
                );

                const firstMatchingScheduleUser: any =
                  element?.schedule_users &&
                  element?.schedule_users?.length > 0 &&
                  element?.schedule_users?.find((user: { user_id?: number }) =>
                    newListUser.some(
                      (item: { value?: number }) => item.value === user.user_id
                    )
                  );
                // tab all

                // tab participation
                const scheduleUsersParticipation = element?.is_user
                  ? element?.schedule_users?.filter(
                      (element: { user_id?: number }) =>
                        element?.user_id === auth?.id
                    )
                  : [];

                const idGroupTabParticipation =
                  (element?.user_id === auth?.id ||
                    (scheduleUsersParticipation?.length ?? 0) > 0 ||
                    (element?.schedule_groups?.length ?? 0) > 0) &&
                  auth?.id;
                // tab participation

                // tab vehicle
                const idGroupTabVehicle = element?.vehicle_id;
                // tab vehicle

                const idGroupTabAll =
                  element?.user_id === auth?.id
                    ? auth?.id
                    : element?.user_id !== auth?.id &&
                        !firstMatchingScheduleUser
                      ? element?.user_id
                      : firstMatchingScheduleUser?.user_id;

                const idGroupResult =
                  tabSchedule.Vehicle === typeTabSchedule
                    ? idGroupTabVehicle
                    : tabSchedule.Participation === typeTabSchedule &&
                        (activeViews.Week === actionView ||
                          activeViews.Day === actionView)
                      ? idGroupTabParticipation
                      : tabSchedule.All === typeTabSchedule &&
                          !actionView?.includes(activeViews.Month)
                        ? idGroupTabAll
                        : element?.user_id;

                const scheduleGroupsResult =
                  typeTabSchedule?.includes(tabSchedule.All) &&
                  !actionView.includes(activeViews.Month)
                    ? listUserScheduleGroupResult
                    : element?.schedule_groups;

                return {
                  id: element?.id,
                  id_schedule: element?.id,
                  title:
                    dayjs(formatEventDate(element?.start_date)).format(
                      formatTitle
                    ) +
                    ' ' +
                    '-' +
                    ' ' +
                    dayjs(formatEventDate(element?.end_date)).format(
                      formatTitle
                    ) +
                    ' ' +
                    element?.content,
                  isEdit: element?.assigned_user?.id === auth?.id,
                  color: element?.color,
                  start: startResult,
                  end: endResult,
                  start_time: element?.start_date
                    ? parsedDateDayWeek(
                        element?.start_date,
                        typeDate.Start,
                        activeViews.Day
                      )
                    : '',
                  end_time: element?.end_date
                    ? parsedDateDayWeek(
                        element?.end_date,
                        typeDate.End,
                        activeViews.Day
                      )
                    : '',
                  user_id: element?.user_id,
                  group: idGroupResult,
                  client_id: element?.client_id,
                  content: element?.content,
                  startDate: startDate,
                  endDate: endDate,
                  is_all_day: element?.is_all_day,
                  is_user: element?.is_user,
                  site_name: element?.site_name,
                  schedule_users: element?.is_user
                    ? element?.schedule_users
                    : [],
                  schedule_groups: !element?.is_user
                    ? scheduleGroupsResult
                    : [],
                  schedule_groups_sub:
                    typeTabSchedule?.includes(tabSchedule.Responsibility) &&
                    !actionView.includes(activeViews.Month)
                      ? listUserScheduleGroups
                      : [],
                  address: element?.address,
                  appointment_request: element?.appointment_request,
                  vehicle_id: element?.vehicle_id
                };
              })
            : [];

        const dataGroupIds = getListEmployeeGroup(schedules);

        const dataEmployeeTabResponsibilityParticipation =
          schedules?.length > 0 && !actionView.includes(activeViews.Month)
            ? getListEmployeeResponsibilityParticipation(schedules)
            : [];

        const dataEmployeeTabVehicle =
          schedules?.length > 0 && !actionView.includes(activeViews.Month)
            ? getListEmployeeVehicle(schedules)
            : [];

        const dataEmployeeTabAll =
          schedules?.length > 0 && !actionView.includes(activeViews.Month)
            ? getListEmployeeAll(schedules)
            : [];

        const dataEmployeeCharge = isTabGroup
          ? dataGroupIds
          : isTabAll
            ? dataEmployeeTabAll
            : isTabResponsibility || isTabParticipation
              ? dataEmployeeTabResponsibilityParticipation
              : dataEmployeeTabVehicle;

        const dataEmployeeResult: Employee[] =
          schedules?.length > 0 && dataEmployeeCharge
            ? dataEmployeeCharge.reduce(
                (
                  acc: Employee[],
                  current: { id: number | undefined; title: any }
                ) => {
                  const ids = acc.map((item) => item.id);
                  if (current?.id !== undefined && !ids.includes(current.id)) {
                    acc.push({ id: current.id, title: current.title });
                  }
                  return acc;
                },
                [] as Employee[]
              )
            : [];

        if (dataEmployeeResult?.length > 0) {
          setListEmployeeCharge(dataEmployeeResult);
        }

        // tab all

        const dataSchedulesTabAllWithoutScheduleGroups =
          dataSchedulesCustom?.filter(
            (element: ScheduleEventI) => element?.schedule_groups?.length === 0
          );

        const dataSchedulesTabAllCustom =
          dataSchedulesCustom?.length > 0 &&
          typeTabSchedule?.includes(tabSchedule.All) &&
          !actionView.includes(activeViews.Month)
            ? dataSchedulesCustom
                .flatMap(
                  (element: ScheduleEventI) =>
                    element.schedule_groups?.map(
                      (group: { group_id?: number; user_id?: number }) => ({
                        ...element,
                        id: stringHelper.uuidv4(),
                        group: group.user_id
                      })
                    ) ?? []
                )
                .concat(dataSchedulesTabAllWithoutScheduleGroups)
            : [];
        // tab all

        // tab responsibility
        const dataSchedulesResponsibilityTabCustom =
          getSchedulesTabResponsibilityParticipation(dataSchedulesCustom);

        const dataSchedulesAllTabCustom =
          getSchedulesTabAll(dataSchedulesCustom);

        // tab responsibility

        // tab group
        const dataSchedulesTabGroupCustom =
          dataSchedulesCustom?.length > 0
            ? dataSchedulesCustom.flatMap(
                (element: ScheduleEventI) =>
                  element.schedule_groups?.map(
                    (group: { group_id: number }) => ({
                      ...element,
                      id: stringHelper.uuidv4(),
                      group: group.group_id
                    })
                  ) ?? []
              )
            : [];
        // tab group

        const dataSchedulesCustomResult =
          typeTabSchedule?.includes(tabSchedule.Group) &&
          !actionView.includes(activeViews.Month)
            ? dataSchedulesTabGroupCustom
            : typeTabSchedule?.includes(tabSchedule.All) &&
                !actionView.includes(activeViews.Month)
              ? dataSchedulesAllTabCustom
              : (typeTabSchedule?.includes(tabSchedule.Responsibility) ||
                    typeTabSchedule?.includes(tabSchedule.Participation)) &&
                  !actionView.includes(activeViews.Month)
                ? dataSchedulesResponsibilityTabCustom
                : dataSchedulesCustom;

        setListScheduleData(dataSchedulesCustomResult);
      },
      onError: (e: any) => {
        e?.response?.status === 500
          ? notification.error({
              message: notifyMessage?.sever_error
            })
          : notification.error({
              message: getErrorMsgFromResponse(e)
            });
      }
    }
  );

  const onChangeTabSchedule = (key: string) => {
    const dateNow = dayjs();
    const { startOfMonth, endOfMonth } = getStartEndDateOfMonth(dateNow);

    setTypeTabSchedule(key as TypeTabScheduleAction);
    formSearch?.resetFields();
    dispatch(setActionView(activeViews.Month));
    dispatch(setStartTime(startOfMonth));
    dispatch(setEndTime(endOfMonth));
    setParams((prev: ISchedule) => {
      return {
        ...prev,
        q: {
          ...prev.q,
          vehicle_id_eq: undefined,
          user_id_eq: undefined
        }
      };
    });
  };

  useEffect(() => {
    const startTimeResult =
      actionView.includes(activeViews.Week) && startTime
        ? dayjs(startTime).format(FORMAT.FORMAT_DATE_UPPER_B)
        : actionView.includes(activeViews.Month) && startTime
          ? startTime
          : undefined;

    const endTimeResult =
      actionView.includes(activeViews.Week) && endTime
        ? dayjs(endTime).format(FORMAT.FORMAT_DATE_UPPER_B)
        : actionView.includes(activeViews.Month) && endTime
          ? endTime
          : undefined;

    const currenViewDayResult = actionView.includes(activeViews.Day)
      ? dayjs(startTime).format(FORMAT.FORMAT_DATE_UPPER_B)
      : undefined;

    let responsible_user_id: number | undefined = undefined;
    let schedule_group_user: number | undefined = undefined;
    let with_vehicle: boolean | undefined = undefined;
    let user_id_eq: number | undefined = undefined;

    switch (typeTabSchedule) {
      case tabSchedule.Responsibility:
        user_id_eq = auth?.id;
        break;
      case tabSchedule.Participation:
        responsible_user_id = auth?.id;
        break;
      case tabSchedule.Group:
        schedule_group_user = auth?.id;
        break;
      case tabSchedule.Vehicle:
        with_vehicle = true;
        break;
    }

    const commonParams = {
      responsible_user_id,
      schedule_group_user,
      with_vehicle,
      start_date: startTimeResult,
      end_date: endTimeResult,
      current_view_day: currenViewDayResult
    };

    const updatedQ = typeTabSchedule?.includes(tabSchedule.All)
      ? { ...params?.q }
      : typeTabSchedule?.includes(tabSchedule.Vehicle)
        ? { vehicle_id_eq: params?.q?.vehicle_id_eq, user_id_eq }
        : { user_id_eq };

    setParams((prev: ISchedule) => ({
      ...prev,
      ...commonParams,
      q: updatedQ
    }));
  }, [typeTabSchedule, actionView, startTime]);

  return (
    <>
      <div className="bg-white pt-5 px-5">
        <div className="flex justify-between items-center">
          <div className="text-xl font-bold">{ACTION.SCHEDULE}管理</div>
          <div>
            <Button
              icon={<PlusOutlined />}
              type="primary"
              onClick={() => {
                openForm();
                setTypeAction('create');
              }}
            >
              {ACTION.CREATE}
            </Button>
          </div>
        </div>
        <div>
          <Tabs
            defaultActiveKey={typeTabSchedule}
            items={items}
            onChange={onChangeTabSchedule}
          />
        </div>
      </div>
      {(typeTabSchedule?.includes(tabSchedule?.Vehicle) ||
        typeTabSchedule?.includes(tabSchedule?.All)) && (
        <div className="bg-white mt-5 mx-5 py-4 pl-3">
          <Form
            layout="vertical"
            className={clsx('flex gap-3 items-center w-full', styles.formInput)}
            form={formSearch}
            onFinish={onSearchSchedule}
          >
            {typeTabSchedule?.includes(tabSchedule?.Vehicle) && (
              <CoreSelect
                formItemClassName={styles.formInput}
                name="vehicle_id_eq"
                className="!mb-0"
                options={newListVehicle}
              />
            )}
            {typeTabSchedule?.includes(tabSchedule?.All) && (
              <>
                <CoreSelect
                  formItemClassName={styles.formInput}
                  name="user_id_eq"
                  className="!mb-0"
                  label="担当社員"
                  options={newListUser}
                />
                <CoreSelect
                  formItemClassName={styles.formInput}
                  name="schedule_users_user_id_eq"
                  className="!mb-0"
                  label="参加社員"
                  options={newListUser}
                />
                <CoreSelect
                  formItemClassName={styles.formInput}
                  name="schedule_groups_group_id_eq"
                  className="!mb-0"
                  label="グループ"
                  options={newListGroup}
                />
                <CoreSelect
                  formItemClassName={styles.formInput}
                  name="vehicle_id_eq"
                  className="!mb-0"
                  label="車両"
                  options={newListVehicle}
                />
                <Input
                  name="key_word"
                  label="キーワード"
                  className={clsx('!mb-0 !h-10', styles.formInput)}
                />
              </>
            )}

            <Button
              className={clsx('h-[38px]', {
                'mt-7': typeTabSchedule?.includes(tabSchedule?.All)
              })}
              type="primary"
              htmlType="submit"
            >
              {ACTION.SEARCH}
            </Button>
          </Form>
        </div>
      )}

      {isLoading && isFetching ? (
        <LoadingSpin />
      ) : (
        <>
          {actionView.includes(activeViews.Month) ? (
            <ScheduleMonth
              showDrawEdit={showDrawEdit}
              listScheduleData={listScheduleData}
            />
          ) : actionView.includes(activeViews.Day) ? (
            <ScheduleDay
              showDrawEdit={showDrawEdit}
              listEmployeeCharge={listEmployeeCharge}
              listScheduleData={listScheduleData}
              typeTabSchedule={typeTabSchedule}
            />
          ) : (
            <ScheduleWeek
              showDrawEdit={showDrawEdit}
              listEmployeeCharge={listEmployeeCharge}
              listScheduleData={listScheduleData}
              typeTabSchedule={typeTabSchedule}
            />
          )}
        </>
      )}

      <ScheduleCU
        newListVehicle={newListVehicle}
        queryClient={queryClient}
        isOpenForm={isOpenForm}
        formDrawer={formDrawer}
        closeDialog={closeDialog}
        isCreateForm={isCreateForm}
        setIsStatusValidateDatePicker={setIsStatusValidateDatePicker}
        isStatusValidateDatePicker={isStatusValidateDatePicker}
        newListUser={newListUser}
        newListGroup={newListGroup}
        valueRadioDate={valueRadioDate}
        valueRadioSelect={valueRadioSelect}
        valueRadioColor={valueRadioColor}
        appointmentRequestCheck={appointmentRequestCheck}
        setAppointmentRequestCheck={setAppointmentRequestCheck}
        setValueRadioColor={setValueRadioColor}
        setValueRadioSelect={setValueRadioSelect}
        setValueRadioDate={setValueRadioDate}
        resetAllFields={resetAllFields}
      />
    </>
  );
};
export default Schedule;
