import { notifyMessage } from './message';

export const formatPostalCode = (data?: string) => {
  let input: any = data;
  input = input?.replace(/[^\w０-９]/g, '')?.replace(/[^\d０-９.%]/g, '');
  if (input.length > 7) input = input.slice(0, 7);
  if (input.length > 3) input = input.slice(0, 3) + '-' + input.slice(3);
  return input;
};

export const formatNumber = (value?: string | number) => {
  if (typeof value === 'number') {
    return value.toLocaleString('en-US');
  } else {
    const valueCustom = value
      ?.replace(/[^\w０-９]/g, '')
      ?.replace(/[^\d０-９.%]/g, '')
      ?.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1');
    return valueCustom;
  }
};

export const getErrorMsgFromResponse = (e: any) => {
  return e?.response?.data?.errors
    ? e?.response?.data?.errors[0]?.message
    : notifyMessage.sever_error;
};

export const formattedNumber = (yourNumber: string | number) => {
  const yourNumberCustom = Number(yourNumber);
  return yourNumberCustom.toLocaleString('en-US');
};
