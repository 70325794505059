import {
  CheckboxProps,
  Checkbox,
  Input,
  Radio,
  Form,
  FormInstance,
  InfoCircleOutlined,
  notification,
  RadioChangeEvent,
  Space,
  CheckOutlined
} from 'core-ui';
import React, { useCallback, useEffect, useState } from 'react';
import { QueryClient, useMutation, useQuery } from 'react-query';
import styles from './styles.module.scss';
import CoreSelect from 'src/components/CoreSelect';
import { getErrorMsgFromResponse } from 'src/constants/format';
import { notifyMessage } from 'src/constants/message';
import { ACTION } from 'src/constants/textAction';
import { validate } from 'src/constants/validate';
import { getClientList } from 'src/services/client';
import Drawer from 'src/components/Drawer';
import { RADIO_COLOR } from 'src/constants/common';
import RangePickerField from 'src/components/Datepicker';
import { FORMAT } from 'src/constants/datetime';
import { dayjs } from 'core-helpers';
import {
  deleteSchedule,
  postScheduleCreate,
  putScheduleUpdate
} from 'src/services/schedule';
import { useSelector } from 'react-redux';
import { RootState } from '../ScheduleDay/CustomTimeLine';
import { ScheduleEventI } from 'src/models/schedule';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from 'src/enums/routePath';
import useModal from 'src/hooks/useModal';
import { ISelect } from 'src/models/select';
interface ScheduleCUProps {
  closeDialog?: () => void;
  resetAllFields?: () => void;
  isOpenForm?: boolean;
  formDrawer?: FormInstance;
  isCreateForm?: boolean;
  isStatusValidateDatePicker?: boolean;
  setIsStatusValidateDatePicker?: (value: boolean) => void;
  setAppointmentRequestCheck?: (value: boolean) => void;
  setValueRadioDate?: (value: boolean) => void;
  setValueRadioSelect?: (value: boolean) => void;
  setValueRadioColor?: (value: number) => void;
  appointmentRequestCheck?: boolean;
  valueRadioDate?: boolean;
  valueRadioSelect?: boolean;
  valueRadioColor?: Number;
  queryClient?: QueryClient;
  newListUser?: ISelect[];
  newListVehicle?: ISelect[];
  newListGroup?: ISelect[];
}

const ScheduleCU: React.FC<ScheduleCUProps> = ({
  closeDialog,
  formDrawer,
  isCreateForm,
  isOpenForm,
  setIsStatusValidateDatePicker,
  isStatusValidateDatePicker,
  queryClient,
  newListUser,
  newListVehicle,
  newListGroup,
  valueRadioSelect,
  valueRadioDate,
  valueRadioColor,
  appointmentRequestCheck,
  resetAllFields,
  setAppointmentRequestCheck,
  setValueRadioDate,
  setValueRadioSelect,
  setValueRadioColor
}) => {
  const navigate = useNavigate();

  const { openModal, closeModal } = useModal();

  const detailSchedule: ScheduleEventI = useSelector(
    (state: RootState) => state?.schedule?.detailSchedule
  );

  let data = formDrawer && formDrawer.getFieldsValue();

  const [errors, setErrors] = useState<{
    date_time: boolean;
    all_day: boolean;
  }>({ date_time: false, all_day: false });

  const onChangeCheckbox: CheckboxProps['onChange'] = (e) => {
    setAppointmentRequestCheck && setAppointmentRequestCheck(e.target.checked);
  };

  const onChangeRadioColor = (data: RadioChangeEvent) => {
    setValueRadioColor && setValueRadioColor(data.target.value);
  };

  const { data: dataListClient } = useQuery(
    ['getDataListClient'],
    () =>
      getClientList({
        all: true
      }),
    {
      refetchOnWindowFocus: false,
      retry: false,
      onError: (e: any) => {
        e?.response?.status === 500
          ? notification.error({
              message: notifyMessage?.sever_error
            })
          : notification.error({
              message: getErrorMsgFromResponse(e)
            });
      }
    }
  );

  const list_client = dataListClient?.data?.clients?.map(
    (element: { id?: number; name?: string }) => {
      return { label: element?.name, value: element?.id };
    }
  );

  const onChangeRadioGroupDate = (data?: RadioChangeEvent) => {
    setValueRadioDate && setValueRadioDate(data?.target?.value);
    formDrawer &&
      formDrawer.setFieldsValue({
        date_time_start: '',
        date_time_end: '',
        all_day_start: '',
        all_day_end: ''
      });
  };

  const onChangeRadioGroupSelect = (data?: RadioChangeEvent) => {
    setValueRadioSelect && setValueRadioSelect(data?.target?.value);
    formDrawer &&
      formDrawer.setFieldsValue({
        user_id_select: undefined,
        group_id_select: undefined
      });
  };

  const validateErrorRangePicker = (
    start: Date | string,
    end: Date | string,
    type: string
  ) => {
    if (!start && !end) {
      setErrors(() => {
        return { ...errors, [type]: true };
      });
    } else {
      setErrors(() => {
        return { date_time: false, all_day: false };
      });
    }
  };

  const handleSubmit = useCallback(async () => {
    setIsStatusValidateDatePicker && setIsStatusValidateDatePicker(true);
    let data = formDrawer?.getFieldsValue();
    try {
      if (
        (formDrawer &&
          (await formDrawer.validateFields()) &&
          !errors.all_day) ||
        !errors.date_time
      ) {
        const schedule_users_custom = (data?.user_id_select || []).map(
          (element: { value: number }) => ({
            user_id: element?.value ? element?.value : element
          })
        );

        const schedule_groups_custom = (data?.group_id_select || []).map(
          (element: { value: number }) => ({
            group_id: element?.value ? element?.value : element
          })
        );

        const formatStartDate = !valueRadioDate
          ? FORMAT.FORMAT_DATE_UPPER_B
          : FORMAT.FORMAT_DATE_UPPER_DATE_TIME_B;

        const formatEndDate = !valueRadioDate
          ? FORMAT.FORMAT_DATE_UPPER_B
          : FORMAT.FORMAT_DATE_UPPER_DATE_TIME_B;
        data = {
          address: data?.address?.trim(),
          is_all_day: !valueRadioDate,
          start_date: valueRadioDate
            ? dayjs(data?.date_time_start)?.format(formatStartDate)
            : dayjs(data?.all_day_start).format(formatStartDate),
          end_date: valueRadioDate
            ? dayjs(data?.date_time_end).format(formatEndDate)
            : dayjs(data?.all_day_end).format(formatEndDate),
          client_id: data?.client_id,
          color: data?.color?.toString(),
          content: data?.content?.trim(),
          is_user: valueRadioSelect,
          schedule_users: schedule_users_custom,
          schedule_groups: schedule_groups_custom,
          site_name: data?.site_name?.trim(),
          user_id: data?.user_id,
          vehicle_id: data?.vehicle_id,
          appointment_request: appointmentRequestCheck
        };

        if (isCreateForm) {
          // create
          createSchedule(data);
        } else {
          // update
          editSchedule(data);
        }
      }
    } catch (err) {
      console.error(err);
    }
  }, [
    errors,
    valueRadioDate,
    valueRadioColor,
    valueRadioSelect,
    appointmentRequestCheck
  ]);

  const createSchedule = (data: ScheduleEventI) => {
    mutateCreateSchedule(data, {
      onSuccess: () => {
        notification.success({ message: notifyMessage.create_success });
        queryClient?.invalidateQueries({
          queryKey: ['getDataListSchedule']
        });
        closeDialog && closeDialog();
      },
      onError: (e: any) => {
        e?.response?.status === 500
          ? notification.error({ message: notifyMessage?.sever_error })
          : notification.error({ message: getErrorMsgFromResponse(e) });
      }
    });
  };

  const dataUser = (list_data_schedule_users: {
    schedule_users: { user_id?: number; group_id?: number }[];
    is_user?: boolean;
  }) => {
    const { schedule_users, is_user } = list_data_schedule_users;

    const schedule_users_after_edit: number[] = schedule_users
      .map((element) => (is_user ? element.user_id : element.group_id))
      .filter((id): id is number => id !== undefined);

    const schedule_users_before_edit: number[] =
      detailSchedule?.is_user && detailSchedule.schedule_users
        ? detailSchedule.schedule_users
            .map((element: { user_id: number }) => element.user_id)
            .filter((user_id): user_id is number => user_id !== undefined)
        : detailSchedule.schedule_groups
          ? detailSchedule.schedule_groups
              .map((element: { group_id: number }) => element.group_id)
              .filter((group_id): group_id is number => group_id !== undefined)
          : [];

    const schedule_users_remove = schedule_users_before_edit.filter(
      (id) => !schedule_users_after_edit.includes(id)
    );

    const schedule_users_add_new = schedule_users_after_edit.filter(
      (id) => !schedule_users_before_edit.includes(id)
    );

    const data_schedule_users_remove = schedule_users_remove.length
      ? detailSchedule?.is_user && detailSchedule.schedule_users
        ? detailSchedule.schedule_users
            .filter((item: { user_id: number }) =>
              schedule_users_remove.includes(item.user_id)
            )
            .map((item: { id: number }) => ({
              id: item.id,
              _destroy: 1
            }))
        : detailSchedule.schedule_groups
          ? detailSchedule.schedule_groups
              .filter((item: { group_id: number }) =>
                schedule_users_remove.includes(item.group_id)
              )
              .map((item: { id: number }) => ({
                id: item.id,
                _destroy: 1
              }))
          : []
      : [];

    const data_schedule_users_add = schedule_users_add_new.length
      ? schedule_users_add_new.map((id: number) => ({
          [is_user ? 'user_id' : 'group_id']: id
        }))
      : [];

    const isSameUser =
      detailSchedule?.is_user === list_data_schedule_users?.is_user;
    return isSameUser
      ? [...data_schedule_users_remove, ...data_schedule_users_add]
      : { data_schedule_users_remove, data_schedule_users_add };
  };

  const editSchedule = (data: ScheduleEventI) => {
    const schedule_users_result: any = dataUser({
      schedule_users: data?.is_user
        ? data.schedule_users ?? []
        : data.schedule_groups ?? [],
      is_user: data?.is_user
    });
    if (detailSchedule?.is_user === data?.is_user) {
      data?.is_user
        ? (data = { ...data, schedule_users: schedule_users_result ?? [] })
        : (data = { ...data, schedule_groups: schedule_users_result ?? [] });
    } else {
      const hasGroupId = schedule_users_result?.data_schedule_users_add?.some(
        (element: { group_id?: number }) => element.group_id !== undefined
      );
      const hasUserId = schedule_users_result?.data_schedule_users_add?.some(
        (element: { user_id?: number }) => element.user_id !== undefined
      );
      data = {
        ...data,
        schedule_users: hasUserId
          ? schedule_users_result?.data_schedule_users_add
          : schedule_users_result.data_schedule_users_remove,
        schedule_groups: hasGroupId
          ? schedule_users_result?.data_schedule_users_add
          : schedule_users_result.data_schedule_users_remove
      };
    }

    mutateEditSchedule(
      {
        data: data,
        id: detailSchedule?.id_schedule
      },
      {
        onSuccess: () => {
          notification.success({ message: notifyMessage.create_success });
          closeDialog && closeDialog();
          queryClient?.invalidateQueries({
            queryKey: ['getDataListSchedule']
          });
        },
        onError: (e: any) => {
          e?.response?.status === 500
            ? notification.error({
                message: notifyMessage?.sever_error
              })
            : e?.response?.status === 404
              ? notification.error({
                  message: e?.response?.data?.errors
                })
              : notification.error({
                  message: getErrorMsgFromResponse(e)
                });
        }
      }
    );
  };

  // validate rangeDatePicker edit schedule
  useEffect(() => {
    if (isOpenForm && !isCreateForm) {
      if (!valueRadioDate) {
        validateErrorRangePicker(
          data?.all_day_start,
          data?.all_day_end,
          'all_day'
        );
      } else {
        validateErrorRangePicker(
          data?.date_time_start,
          data?.date_time_end,
          'date_time'
        );
      }
    }
  }, [formDrawer, errors, isStatusValidateDatePicker, isOpenForm]);

  // validate rangeDatePicker create schedule
  useEffect(() => {
    if (isStatusValidateDatePicker && isCreateForm) {
      if (valueRadioDate) {
        validateErrorRangePicker(
          data?.date_time_start,
          data?.date_time_end,
          'date_time'
        );
      } else {
        validateErrorRangePicker(
          data?.all_day_start,
          data?.all_day_end,
          'all_day'
        );
      }
    }
  }, [formDrawer, errors, isStatusValidateDatePicker, valueRadioDate]);

  const convertToFieldsValue = () => {
    const schedule_users_custom = (detailSchedule?.schedule_users || []).map(
      (element: { id: number; user_id: number }) => ({
        value: element?.user_id,
        schedule_user_id: element.id
      })
    );

    const schedule_groups_custom = (detailSchedule?.schedule_groups || []).map(
      (element: { id: number; group_id: number }) => ({
        value: element?.group_id,
        schedule_group_id: element.id
      })
    );
    let result = {
      ...detailSchedule,
      all_day_start:
        detailSchedule?.is_all_day && detailSchedule?.startDate
          ? detailSchedule?.startDate
          : '',
      all_day_end:
        detailSchedule?.is_all_day && detailSchedule?.endDate
          ? detailSchedule?.endDate
          : '',
      date_time_start:
        !detailSchedule?.is_all_day && detailSchedule?.startDate
          ? dayjs(detailSchedule?.startDate)?.format(
              FORMAT.FORMAT_DATE_UPPER_DATE_TIME_B
            )
          : '',
      date_time_end:
        !detailSchedule?.is_all_day && detailSchedule?.endDate
          ? dayjs(detailSchedule?.endDate).format(
              FORMAT.FORMAT_DATE_UPPER_DATE_TIME_B
            )
          : '',
      user_id_select: detailSchedule?.is_user ? schedule_users_custom : [],
      group_id_select: !detailSchedule?.is_user ? schedule_groups_custom : []
    };
    return result;
  };

  useEffect(() => {
    // setFieldsValue
    if (!isCreateForm) {
      const fieldsValue = convertToFieldsValue();
      const conditionValidateRangePicker = () => {
        if (!fieldsValue?.is_all_day) {
          validateErrorRangePicker(
            fieldsValue?.date_time_start,
            fieldsValue?.date_time_end,
            'date_time'
          );
        } else {
          validateErrorRangePicker(
            fieldsValue?.all_day_start,
            fieldsValue?.all_day_end,
            'all_day'
          );
        }
      };
      conditionValidateRangePicker();
      formDrawer?.setFieldsValue(fieldsValue);

      setAppointmentRequestCheck &&
        setAppointmentRequestCheck(
          detailSchedule?.appointment_request ?? false
        );
      detailSchedule?.color &&
        setValueRadioColor &&
        setValueRadioColor(Number(detailSchedule?.color));
      setValueRadioDate && setValueRadioDate(!detailSchedule?.is_all_day);
      setValueRadioSelect &&
        setValueRadioSelect(detailSchedule?.is_user ? true : false);
    } else {
      resetAllFields && resetAllFields();
    }
  }, [formDrawer, isCreateForm, detailSchedule, isOpenForm]);

  const openConfirmDeleteAlert = (id?: number) => {
    openModal({
      content: (
        <div className="flex justify-center">
          <div className="text-center">
            <InfoCircleOutlined className="text-[73px] text-[#d83535] text-center mb-5" />
            <div className="text-base">本当に削除しますか？</div>
          </div>
        </div>
      ),
      cancelButtonLabel: 'いいえ',
      okeButtonLabel: 'はい',
      okeButtonStyle: {
        backgroundColor: '#d83535'
      },
      modalProps: {
        width: 400
      },
      onOke: () => {
        if (id) {
          mutateDeleteSchedule(
            { id: id },
            {
              onSuccess: () => {
                notification.success({
                  message: notifyMessage.delete_success
                });
                queryClient?.invalidateQueries({
                  queryKey: ['getDataListSchedule']
                });
                closeModal();
                closeDialog && closeDialog();
              },
              onError: (e: any) => {
                e?.response?.status === 500
                  ? notification.error({
                      message: notifyMessage?.sever_error
                    })
                  : e?.response?.status === 404
                    ? notification.error({
                        message: e?.response?.data?.errors
                      })
                    : notification.error({
                        message: getErrorMsgFromResponse(e)
                      });
              }
            }
          );
        }
      }
    });
  };

  // api call create schedule
  const { mutate: mutateCreateSchedule } = useMutation(postScheduleCreate);

  // api call edit schedule
  const { mutate: mutateEditSchedule } = useMutation(putScheduleUpdate);

  // api delete  schedule
  const { mutate: mutateDeleteSchedule } = useMutation(deleteSchedule);

  const isDetail = !detailSchedule?.isEdit && !isCreateForm;

  const isEdit = !isCreateForm && detailSchedule?.isEdit;

  const isDisabled = !detailSchedule?.isEdit && !isCreateForm;

  return (
    <>
      <Drawer
        title={
          isDisabled
            ? ACTION.INQUIRY
            : isCreateForm
              ? ACTION.SCHEDULE + ACTION.CREATE
              : ACTION.EDIT
        }
        isOpen={isOpenForm}
        onCancel={closeDialog}
        closeDrawer={closeDialog}
        onOke={handleSubmit}
        onBtnCustom={() => {
          navigate(RoutePath.ADMIN_INVOICE_ISSUES_CREATE);
        }}
        onDelete={() => {
          openConfirmDeleteAlert(Number(detailSchedule?.id_schedule));
        }}
        okClassName={isDetail ? '!hidden' : ''}
        deleteClassName={!isEdit ? '!hidden' : ''}
        okeButtonLabel={ACTION.REGISTRATION}
        deleteButtonLabel={ACTION.DELETE}
        cancelButtonLabel={ACTION.CANCEL}
        buttonCustomLabel={ACTION.QUANTITY_INPUT}
        width={527}
        buttonCustomClassName={isCreateForm ? '!hidden' : ''}
      >
        <Form
          layout="vertical"
          form={formDrawer}
          className={isDisabled ? styles.formScheduleCU : ''}
        >
          <CoreSelect
            name="user_id"
            label="担当社員"
            options={newListUser}
            rules={[
              (() => ({
                required: !isDisabled,
                validator(_: any, value: string) {
                  if (!value) {
                    return Promise.reject(
                      new Error(`担当社員${validate?.validate_input}`)
                    );
                  }
                  return Promise.resolve();
                }
              }))()
            ]}
            className="h-10"
            disabled={isDisabled}
            formItemClassName={styles.formInput}
          />
          <Input
            name="site_name"
            label="現場名"
            rules={[
              () => ({
                required: !isDisabled,
                validator(_: any, value: string) {
                  if (!value?.trim()) {
                    return Promise.reject(
                      new Error(`現場名${validate?.validate_input}`)
                    );
                  }
                  return Promise.resolve();
                }
              })
            ]}
            className="h-10"
            disabled={isDisabled}
          />
          <CoreSelect
            name="client_id"
            label="取引先"
            options={list_client}
            formItemClassName={styles.formInput}
            rules={[
              (() => ({
                required: !isDisabled,
                validator(_: any, value: string) {
                  if (!value) {
                    return Promise.reject(
                      new Error(`取引先${validate?.validate_input}`)
                    );
                  }
                  return Promise.resolve();
                }
              }))()
            ]}
            className="!h-10"
            disabled={isDisabled}
          />
          <Input
            name="content"
            label="内容"
            rules={[
              () => ({
                required: !isDisabled,
                validator(_: any, value: string) {
                  if (!value?.trim()) {
                    return Promise.reject(
                      new Error(`内容${validate?.validate_input}`)
                    );
                  }
                  return Promise.resolve();
                }
              })
            ]}
            className="h-10"
            disabled={isDisabled}
          />

          <div className={styles.radioScheduleCon}>
            <Radio
              value={true}
              onChange={(event) => {
                onChangeRadioGroupDate(event);
              }}
              checked={valueRadioDate}
            >
              <div className="flex">
                {valueRadioDate && !isDisabled && (
                  <p className="text-[red] mr-1">*</p>
                )}
                <p>日時</p>
              </div>
            </Radio>

            <RangePickerField
              nameStart="date_time_start"
              nameEnd="date_time_end"
              form={formDrawer}
              isDisabled={!valueRadioDate || isDisabled}
              isCreateForm={isCreateForm}
              isOpenForm={isOpenForm}
              showTimeSelect={true}
              dateFormat={FORMAT.FORMAT_DATE_UPPER_DATE_TIME}
            />

            <div className="text-[red] text-sm mt-1">
              {isStatusValidateDatePicker && valueRadioDate && errors?.date_time
                ? `日時${validate?.validate_input}`
                : ''}
            </div>

            <Radio
              value={false}
              className="mt-4"
              onChange={(event) => {
                onChangeRadioGroupDate(event);
              }}
              checked={!valueRadioDate}
            >
              <div className="flex">
                {!valueRadioDate && !isDisabled && (
                  <p className="text-[red] mr-1">*</p>
                )}
                <p>終日</p>
              </div>
            </Radio>

            <RangePickerField
              nameStart="all_day_start"
              nameEnd="all_day_end"
              form={formDrawer}
              isDisabled={valueRadioDate || isDisabled}
              isCreateForm={isCreateForm}
              isOpenForm={isOpenForm}
              showTimeSelect={false}
              dateFormat={FORMAT.FORMAT_DATE_UPPER_DATE_PICKER}
            />

            <div className="text-[red] text-sm mt-1">
              {isStatusValidateDatePicker && !valueRadioDate && errors?.all_day
                ? `終日${validate?.validate_input}`
                : ''}
            </div>
          </div>

          <Input
            name="address"
            label="場所"
            className="h-10"
            disabled={isDisabled}
          />
          <div className={styles.radioScheduleCon}>
            <Radio
              value={true}
              onChange={(event) => {
                onChangeRadioGroupSelect(event);
              }}
              checked={valueRadioSelect}
            >
              <div className="flex">
                <p>参加社員</p>
              </div>
            </Radio>
          </div>
          <CoreSelect
            formItemClassName={styles.formInput}
            mode={'multiple'}
            name="user_id_select"
            options={newListUser}
            className={`!w-full h-10 ${styles.formInput}`}
            disabled={!valueRadioSelect || isDisabled}
          />
          <Radio
            value={false}
            onChange={(event) => {
              onChangeRadioGroupSelect(event);
            }}
            checked={!valueRadioSelect}
          >
            <div className="flex">
              <p>グループ</p>
            </div>
          </Radio>
          <CoreSelect
            formItemClassName={styles.formInput}
            mode={'multiple'}
            name="group_id_select"
            options={newListGroup}
            className={`!w-full h-10 ${styles.formInput}`}
            disabled={valueRadioSelect || isDisabled}
          />
          <Checkbox
            className="mb-4"
            checked={appointmentRequestCheck}
            onChange={onChangeCheckbox}
            name="appointment_request"
            disabled={isDisabled}
          >
            アポイント参加依頼
          </Checkbox>
          <CoreSelect
            formItemClassName={styles.formInput}
            name="vehicle_id"
            label="車両"
            options={newListVehicle}
            disabled={isDisabled}
          />
          <div className="flex gap-2 mb-10">
            <div className={`${isDisabled ? 'text-[#00000040]' : ''} mt-1`}>
              色
            </div>
            <div>
              <Form.Item name="color">
                <div className={styles.radioColor}>
                  <Space direction="horizontal">
                    {RADIO_COLOR.map(
                      (element: { value?: number; class?: string }) => (
                        <Radio
                          disabled={isDisabled}
                          className={`${valueRadioColor === element?.value && 'transform'} ${element?.class ? styles[element.class] : ''} flex justify-center items-center`}
                          value={element?.value}
                          checked={valueRadioColor === element?.value}
                          onChange={onChangeRadioColor}
                        >
                          {valueRadioColor === element?.value && (
                            <CheckOutlined className="text-white" />
                          )}
                        </Radio>
                      )
                    )}
                  </Space>
                </div>
              </Form.Item>
            </div>
          </div>
        </Form>
      </Drawer>
    </>
  );
};

export default ScheduleCU;
