import { TimelineItemBase } from 'react-calendar-timeline';

export const list_name = [
  { label: 'kizito', value: '1' },
  { label: 'kizito 1', value: '2' }
];

export const CLIENT_NAME = [
  { label: '取引先A', value: 1 },
  { label: '取引先B', value: 2 },
  { label: '取引先C', value: 3 },
  { label: '取引先D', value: 4 },
  { label: '取引先E', value: 5 }
];

export const STATUS = [
  { label: '未入金', value: 'unpaid' },
  { label: '回収済み', value: 'paid_in_full' },
  { label: '不足', value: 'underpaid' },
  { label: '過剰', value: 'overpaid' }
];

export const ITEM_NAME = [
  { label: 'Product A', value: 1 },
  { label: 'Product B', value: 2 },
  { label: 'Product C', value: 3 },
  { label: 'Product D', value: 4 },
  { label: 'Product E', value: 5 },
  { label: 'Product F', value: 6 },
  { label: 'Product G', value: 7 },
  { label: 'Product H', value: 8 },
  { label: 'Product K', value: 9 },
  { label: 'Product J', value: 10 }
];

export const UNIT_NAME = [
  { label: 'unit A', value: 1 },
  { label: 'unit B', value: 2 },
  { label: 'unit C', value: 3 },
  { label: 'unit D', value: 4 },
  { label: 'unit F', value: 5 }
];

export const IS_DAY = [
  { value: 'false', label: '日' },
  { value: 'true', label: '月末' }
];

export const DAY_GROUP = [
  { value: 'true', label: '日時' },
  { value: 'false', label: '終日' }
];

export const IS_INCLUDING_TAX = [
  { value: 'false', label: '税込み' },
  { value: 'true', label: '税抜き' }
];

export const RADIO_COLOR = [
  {
    class: 'circle1',
    value: 1,
    color: '#0085FF',
    bgColor: 'rgba(0, 133, 255, 0.1)'
  },
  {
    class: 'circle2',
    value: 2,
    color: '#FF9F2D',
    bgColor: 'rgba(255, 159, 45, 0.1)'
  },
  {
    class: 'circle3',
    value: 3,
    color: '#E92C2C',
    bgColor: 'rgba(233, 44, 44, 0.1)'
  },
  {
    class: 'circle4',
    value: 4,
    color: '#585757',
    bgColor: 'rgba(88, 87, 87, 0.1)'
  },
  {
    class: 'circle5',
    value: 5,
    color: '#2F8C33',
    bgColor: 'rgba(47, 140, 51, 0.1)'
  },
  {
    class: 'circle6',
    value: 6,
    color: '#EF5DA8',
    bgColor: 'rgba(239, 93, 168, 0.1)'
  },
  {
    class: 'circle7',
    value: 7,
    color: '#D9D9D9',
    bgColor: 'rgba(217, 217, 217, 0.1)'
  },
  {
    class: 'circle8',
    value: 8,
    color: '#5D5FEF',
    bgColor: 'rgba(93, 95, 239, 0.1)'
  },
  {
    class: 'circle9',
    value: 9,
    color: '#AF5400',
    bgColor: 'rgba(175, 84, 0, 0.1)'
  }
];
export const DATA_EMPTY = 'データがありません。';

export const GROUPS_DAY = [
  { id: '1', title: 'Humberto', rightTitle: "D'Amore", bgColor: '#f9977f' },
  { id: '2', title: 'Yadira', rightTitle: 'Ratke', bgColor: '#f7bb8a' },
  { id: '3', title: 'Estella', rightTitle: 'Mills', bgColor: '#f4d797' },
  { id: '4', title: 'Vicky', rightTitle: 'Hahn', bgColor: '#f9f2a2' },
  { id: '5', title: 'Timmothy', rightTitle: 'Waelchi', bgColor: '#eefca6' }
];

export const ITEMS_DAY: CustomTimelineItem[] = [
  {
    id: '14',
    group: '4',
    title:
      'The PNG bus is down 1, index the redundant protocol so we can back up the SDD transmitter!',
    start_time: new Date(2024, 8, 12, 14, 0).getTime(),
    end_time: new Date(2024, 8, 12, 18, 0).getTime(),
    start: new Date(2024, 8, 12, 14, 0).getTime(),
    end: new Date(2024, 8, 12, 18, 0).getTime(),
    bgColor: 'orange'
  },
  {
    id: '200',
    group: '4',
    title:
      'The PNG bus is down 2, index the redundant protocol so we can back up the SDD transmitter!',
    start_time: new Date(2024, 8, 6, 13, 0).getTime(),
    end_time: new Date(2024, 8, 6, 18, 0).getTime(),
    start: new Date(2024, 8, 6, 14, 0).getTime(),
    end: new Date(2024, 8, 6, 18, 0).getTime(),
    bgColor: 'blue'
  },
  {
    id: '201',
    group: '4',
    title:
      'The PNG bus is down 3, index the redundant protocol so we can back up the SDD transmitter!',
    start_time: new Date(2024, 8, 6, 13, 0).getTime(),
    end_time: new Date(2024, 8, 6, 18, 0).getTime(),
    start: new Date(2024, 8, 6, 14, 0).getTime(),
    end: new Date(2024, 8, 6, 18, 0).getTime(),
    bgColor: '#e8e8e8'
  },
  {
    id: '202',
    group: '4',
    title:
      'The PNG bus is down 4, index the redundant protocol so we can back up the SDD transmitter!',
    start_time: new Date(2024, 8, 6, 13, 0).getTime(),
    end_time: new Date(2024, 8, 6, 18, 0).getTime(),
    start: new Date(2024, 8, 6, 14, 0).getTime(),
    end: new Date(2024, 8, 6, 18, 0).getTime(),
    bgColor: 'red'
  }
];

export const GROUPS_WEEK = [
  { id: 1, title: 'Humberto' },
  { id: 2, title: 'Yadira' },
  { id: 3, title: 'Estella' },
  { id: 4, title: 'Vicky' },
  { id: 5, title: 'Timmothy' }
];
export const ITEMS_WEEK: CustomTimelineItem[] = [
  {
    id: '14',
    group: 4,
    title:
      'The PNG bus is down, index the redundant protocol so we can back up the SDD transmitter!',
    start_time: new Date(2024, 8, 3).getTime(),
    end_time: new Date(2024, 8, 6).getTime(),
    start: new Date(2024, 8, 3).getTime(),
    end: new Date(2024, 8, 6).getTime(),
    bgColor: 'orange'
  },
  {
    id: '200',
    group: 4,
    title:
      'The PNG bus is down, index the redundant protocol so we can back up the SDD transmitter!',
    start_time: new Date(2024, 8, 3).getTime(),
    end_time: new Date(2024, 8, 6).getTime(),
    start: new Date(2024, 8, 3).getTime(),
    end: new Date(2024, 8, 6).getTime(),
    bgColor: 'blue'
  },
  {
    id: '201',
    group: 4,
    title:
      'The PNG bus is down, index the redundant protocol so we can back up the SDD transmitter!',
    start_time: new Date(2024, 8, 3).getTime(),
    end_time: new Date(2024, 8, 8).getTime(),
    start: new Date(2024, 8, 3).getTime(),
    end: new Date(2024, 8, 8).getTime(),
    bgColor: '#e8e8e8'
  },
  {
    id: '202',
    group: 4,
    title:
      'The PNG bus is down, index the redundant protocol so we can back up the SDD transmitter!',
    start_time: new Date(2024, 8, 3).getTime(),
    end_time: new Date(2024, 8, 6).getTime(),
    start: new Date(2024, 8, 3).getTime(),
    end: new Date(2024, 8, 6).getTime(),
    bgColor: 'red'
  }
];

interface CustomTimelineItem extends TimelineItemBase<number> {
  bgColor?: string;
  start?: number;
  end?: number;
}
